import { ChangeEvent, useState } from "react";
import RedButton from "../Buttons/RedButton";
import { api } from "../../api";
import { toast } from "react-toastify";
import {HomepageInviteRequestInfo} from "../../types/HomepageInviteRequestInfo";
import {TOAST_STATUSES} from "../../utils/toast_statuses";

export interface HomepageInviteRequestModalProps {
  onClose?: () => void;
  onSuccess?: () => void;
}

const HomepageInviteRequestModal = ({ onClose, onSuccess }: HomepageInviteRequestModalProps) => {
  const [inviteRequestInfo, setInviteRequestInfo] = useState<HomepageInviteRequestInfo>({
    email: "",
    name: "",
    company: "",
    position: "",
    referralSource: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInviteRequestInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await toast.promise(
        api.invitations.createHomepageInviteRequest(inviteRequestInfo),
        TOAST_STATUSES.requestInvite
      );
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error("Error submitting invite request:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const isEmailValid = inviteRequestInfo.email.trim().length > 0;

  return (
    <div>
      <div className="mb-5">
        <h2 className="text-2xl text-gray-700 tracking-wide text-center">
          Request an Invitation to Volas
        </h2>
      </div>

      <div className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email *
          </label>
          <input
            id="email"
            name="email"
            type="email"
            required
            autoFocus={true}
            className="mt-1 text-sm text-gray-500 h-10 border-b border-gray-300 p-2 w-full focus:outline-none"
            value={inviteRequestInfo.email}
            onChange={handleInputChange}
            placeholder="your.email@example.com"
          />
        </div>

        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            className="mt-1 text-sm text-gray-500 h-10 border-b border-gray-300 p-2 w-full focus:outline-none"
            value={inviteRequestInfo.name}
            onChange={handleInputChange}
            placeholder="Your Name"
          />
        </div>

        <div>
          <label htmlFor="company" className="block text-sm font-medium text-gray-700">
            Company
          </label>
          <input
            id="company"
            name="company"
            type="text"
            className="mt-1 text-sm text-gray-500 h-10 border-b border-gray-300 p-2 w-full focus:outline-none"
            value={inviteRequestInfo.company}
            onChange={handleInputChange}
            placeholder="Your Company"
          />
        </div>

        <div>
          <label htmlFor="position" className="block text-sm font-medium text-gray-700">
            Position
          </label>
          <input
            id="position"
            name="position"
            type="text"
            className="mt-1 text-sm text-gray-500 h-10 border-b border-gray-300 p-2 w-full focus:outline-none"
            value={inviteRequestInfo.position}
            onChange={handleInputChange}
            placeholder="Your Position"
          />
        </div>

        <div>
          <label htmlFor="referralSource" className="block text-sm font-medium text-gray-700">
            How did you hear about us?
          </label>
          <input
            id="referralSource"
            name="referralSource"
            type="text"
            className="mt-1 text-sm text-gray-500 h-10 border-b border-gray-300 p-2 w-full focus:outline-none"
            value={inviteRequestInfo.referralSource}
            onChange={handleInputChange}
            placeholder="How did you hear about Volas?"
          />
        </div>
      </div>

      <div className="mt-6 flex justify-center">
        <RedButton
          onClickEvent={handleSubmit}
          styles="w-44 px-3 py-3 my-2 text-xs"
          title="REQUEST INVITE"
          disabled={isLoading || !isEmailValid}
        />
      </div>
    </div>
  );
};

export default HomepageInviteRequestModal;
