import { InvitationRecipient } from "../types/Invitations";
import { post, get, del } from "./axios";
import { Invitation } from "../models/Invitation";
import { buildQueryParams } from "../utils/helpers";
import { setAccessToken, setRefreshToken } from "./local-storage";
import { LoginDto } from "./auth";
import {HomepageInviteRequestInfo} from "../types/HomepageInviteRequestInfo";

/**
 * Creates multiple invitations
 *
 * If the recipient does not exist in the DB, an invitation email is sent with a
 * link containing an invitation token. When the recipient clicks the link, they
 * should be redirected to the create account page. After the account is
 * created, the `acceptResourceInvite` method should be called with the
 * invitation token from the email as an argument. This will add the user to
 * that resource and also create a network connection between the recipient and
 * the sender. The user should be notified that they have been added to the
 * resource.
 *
 * If the recipient already exists in the DB, an invitation email is sent with a
 * different link containing an invitation token. When the recipient clicks this
 * link, they will be redirected to a page that should contain a component with
 * a useEffect hook that calls the `acceptResourceInvite` method, passing in the
 * invitation token as an argument. The user should then be notified that they
 * were added to the resource.
 *
 * If the resource type is "document" and the user is already in the DB, an
 * email will simply be sent a notification email that they were added to the
 * document. They will not need to accept the invite.
 *
 * If the resource type is "document" and the user is not in the DB, an
 * invitation email will be sent. When the recipient completes the account
 * creation process, the `acceptResourceInvite` method should be called just as
 * above.
 *
 * @param recipients the invitation recipients
 * @param resourceType the type of resource (ex. aircraft, transaction, document)
 * @param resourceId the id of the resource (ex. aircraft, transaction, document)
 */
const createInvitations = async (
  recipients: InvitationRecipient[],
  // @todo update to UserResourceType
  resourceType: "aircraft" | "transaction" | "document" | "application",
  resourceId: string
): Promise<void> => {
  try {
    await post("/invitation", {
      recipients: recipients.map((x) => ({
        email: x.email,
        permissionLevel: x.permission,
        resourceRoleIds: x.resourceRoleIds,
      })),
      resourceType,
      resourceId,
    });
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Accepts an invite to a resource (ex. aircraft, transaction) or network invite
 *
 * @param token the invitation token
 */
const acceptResourceInvite = async (token: string): Promise<LoginDto> => {
  try {
    const res = await post(`/invitation/accept/${token}`);
    const { user, tokens, resource } = res.data;

    setAccessToken(tokens.access.token);
    setRefreshToken(tokens.refresh.token);

    return { user, tokens, resource }
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Validates the invite token
 * returns the user if it exists
 *
 * @param token the invitation token
 */
const validateInviteToken = async (token: string): Promise<any | undefined> => {
  try {
    const res = await post(`/invitation/validate/${token}`);
    const user = res.data;

    return user
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * @returns InvitedUsers <Invitation[]>
 */
const getOutgoingApplicationInvitations = async (): Promise<Invitation[]> => {
  try {
    const res = await get(`/invitation/sent`);
    const invitedUsers = res.data;
    return invitedUsers;
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * @returns InvitedUsers <Invitation[]>
 */
const getIncomingApplicationInvitations = async (): Promise<Invitation[]> => {
  try {
    const res = await get(`/invitation/application/incoming`);
    const invitedUsers = res.data;
    return invitedUsers;
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
};

const getAircraftInvitations = async (): Promise<Invitation[]> => {
  try {
    const res = await get(`/invitation/sent/user/aircraft`);
    const invitedUsers = res.data;
    return invitedUsers;
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
};

const getTransactionInvitations = async (): Promise<Invitation[]> => {
  try {
    const res = await get(`/invitation/sent/user/transactions`);
    const invitedUsers = res.data;
    return invitedUsers;
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
};

const getDocumentInvitations = async (): Promise<Invitation[]> => {
  try {
    const res = await get(`/invitation/sent/user/documents`);
    const invitedUsers = res.data;
    return invitedUsers;
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
};

const deleteInvitation = async (invitationId: string) => {
  try {
    const query = buildQueryParams({ invitationId }, []);
    await del(`/invitation?${query}`);
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Requests an invitation to Volas
 *
 * @param homepageInviteRequestInfo info sent from user request an invite
 */
const createHomepageInviteRequest = async (homepageInviteRequestInfo: HomepageInviteRequestInfo): Promise<void> => {
  try {
    await post(`/invitation/request`, {homepageInviteRequestInfo});
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

export const invitations = {
  createInvitations,
  createHomepageInviteRequest,
  acceptResourceInvite,
  getOutgoingApplicationInvitations,
  getIncomingApplicationInvitations,
  getAircraftInvitations,
  getTransactionInvitations,
  getDocumentInvitations,
  deleteInvitation,
  validateInviteToken
};
