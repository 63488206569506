import React, { CSSProperties } from "react";

interface ButtonProps {
  onClick: () => void;
  title: string;
}

export const RequestInviteButton: React.FC<ButtonProps> = ({ onClick, title }) => {
  return (
    <button
      className="mt-4 px-6 flex border-2 h-12 border-red-700 justify-center items-center hover:bg-gray-600 text-white tracking-wider rounded text-xs self-center"
      onClick={onClick}
    >
      {title}
    </button>
  );
};
