import React, { useContext, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import x from "../../images/black-x.svg";
import {
  ModalOptions,
  AircraftModalProps,
  DocModalProps,
  EditProfileModalProps,
  DeleteModalProps,
  InvitationModalProps,
  SuccessModalProps,
  PrivacyModalProps,
  TransactionErrorsModalProps
} from '../../types/Modal';
import DeleteModal from "./DeleteModal";
import { ModalContext } from "../../utils/store";
import AircraftModal from "../Aircraft/AircraftModal";
import DocumentModal from "../Documents/DocumentModal";
import InvitationModal from "../Invitations/InvitationModal";
import OnboardingModal from "../Onboarding/OnboardingModal";
import EditProfileModal from "../Profile/Overview/EditProfileModal";
import CreateModal from "../Transactions/CreateModal";
import ConfirmFinalizeModal from "./ConfirmFinalizeModal";
import EditUserModal from "./EditUserModal";
import TagsModal, { TagsModalProps } from "./TagsModal";
import PrivacySettingsModal from "./PrivacySettingsModal";
import SuccessModal from "./SuccessModal";
import TransactionErrorsModal from "./TransactionErrorsModal";
import HomepageInviteRequestModal, {HomepageInviteRequestModalProps} from "./HomepageInviteRequestModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50.1%, -50.1%)",
    backgroundColor: "#fdfdfd",
    zIndex: 50,
    maxHeight: "90vh",
    minWidth: "340px",
    maxWidth: "96vw",
    overflow: "auto",
    boxShadow:
      "0 4px 24px 0 rgba(121, 121, 121, 0.68), 0 4px 4px 0 rgba(0, 0, 0, 0.25)",
  },
};

const ModalWrapper: React.FC<{}> = () => {
  const { modalData } = useContext(ModalContext);

  const {
    childModal,
    childModalProps,
    modalIsOpen,
    setIsOpen,
    navigateLocation,
  } = modalData;

  const navigate = useNavigate();

  const closeModal = () => {
    setIsOpen(false);
    navigateLocation && navigate(navigateLocation);
  };

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflowY = "hidden";
    }

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [modalIsOpen]);

  const retrieveChildModal = () => {
    switch (childModal) {
      case ModalOptions.Document:
        return <DocumentModal {...(childModalProps as DocModalProps)} />;
      case ModalOptions.Share:
        return <InvitationModal {...childModalProps as InvitationModalProps} />;
      case ModalOptions.Success:
        return <SuccessModal {...(childModalProps as SuccessModalProps)} />;
      case ModalOptions.Privacy:
        return (
          <PrivacySettingsModal {...(childModalProps as PrivacyModalProps)} />
        );
      case ModalOptions.EditInfo:
        return (
          <EditProfileModal {...(childModalProps as EditProfileModalProps)} />
        );
      case ModalOptions.Onboard:
        return <OnboardingModal {...childModalProps} />;
      case ModalOptions.Aircraft:
        return <AircraftModal {...(childModalProps as AircraftModalProps)} />;
      case ModalOptions.InitiateTx:
        return <CreateModal {...childModalProps} />;
      case ModalOptions.EditResourceUser:
        return <EditUserModal {...childModalProps} />;
      case ModalOptions.Tags:
        return <TagsModal {...childModalProps as TagsModalProps} />;
      case ModalOptions.HompageInviteRequest:
        return <HomepageInviteRequestModal {...childModalProps as HomepageInviteRequestModalProps} />;
      case ModalOptions.DeleteInvite:
        return <DeleteModal {...(childModalProps as DeleteModalProps)} />;
      case ModalOptions.DeleteResource:
        return <DeleteModal {...(childModalProps as DeleteModalProps)} />;
      case ModalOptions.ConfirmFinalize:
        return <ConfirmFinalizeModal {...childModalProps} />;
      case ModalOptions.TransactionErrors:
        return <TransactionErrorsModal {...childModalProps as TransactionErrorsModalProps} />;
      case ModalOptions.Default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      overlayClassName="overlay"
      closeTimeoutMS={200}
    >
      <img
        src={x}
        alt=""
        className="text-lg cursor-pointer absolute top-0 right-0 mt-4 md:mt-2 mr-3 h-5"
        onClick={closeModal}
      />
      {retrieveChildModal()}
    </Modal>
  );
};

export default ModalWrapper;

Modal.setAppElement("#root");
